<script setup>
import { useI18n } from "vue-i18n";
import { useLoginPopUp } from "@/stores/login-popup-visibility";

const store = useLoginPopUp();
const { t } = useI18n();
</script>
<template>
  <div
    class="bg-[#f2f0eb] text-center md:flex md:space-x-2 md:justify-center md:items-center md:space-y-0 gap-4 text-cerise-red-600 space-y-6 py-8"
  >
    <h1 class="font-extrabold text-xl leading-5 text-brand-gray">
      {{ t("home.loginTitle") }}
    </h1>

    <CoreButton @click="store.open" class="mt-3 lg:mt-0 bg-cerise-red-600">
      {{ t("buttons.login") }}
    </CoreButton>
  </div>
</template>
